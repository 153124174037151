<template>
<svg version="1.1" id="map-linear" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 623.5 572.7" style="enable-background:new 0 0 623.5 572.7;">
<g id="map-linear">
	<path class="st0" d="M335,386.3c4.5,0.2,8.9,0.5,13.4,0.7c2.7-8.5,11.4-8.9,17.8-12.1c4.2-7.3,11.8-11.1,19.3-13c0.8,0,1.5,0,2.3,0
		l0.1-0.1c-0.9,0-1.8,0.1-2.7,0.1c-1.6-13.6-5.7-21-10.9-25.5c-4.6-0.1-9.2-0.2-13.8-0.4c-0.1-4.3-0.2-8.7-0.4-13
		c-8.1-6.1-16.2-12.2-24.3-18.2c0-0.1,0.1-0.2,0.1-0.3c-2.4,0.3-4.8,0.7-7.2,1c-1.7,5.8-3.3,11.6-4.9,17.5c-7.3,5.2-12,11.3-13,18.9
		c-0.1,0.3-0.3,0.6-0.4,1c8.3,7,14.1,15.1,18.9,23.7c-1.5,8.4-1.9,16.2,5.8,20C335,386.6,335,386.4,335,386.3z"/>
	<path class="st0" d="M424,362c-2.3,3.2-4.6,6.5-6.9,9.7c1,2.7,1.2,5.2-0.1,7.4c-0.4,1.1-0.8,2.2-1.2,3.3l0,0.1l0.6-0.2
		c0.3-0.8,0.6-1.6,0.9-2.4c16.4-9.5,32.1-20.4,48.5-29.9c0.4,0.5,0.8,1,1.2,1.6c-0.3-0.4-0.5-0.8-0.8-1.3c2.1-5.7,4.5-7.1,6.7-12.7
		c-0.1,0.1-0.1,0.1-0.2,0.2c-6.3-16.8-14-31.8-24.8-39.5c0-0.7,0-1.5-0.1-2.2l-0.1,0c0,0.8,0,1.6,0,2.5c-3.7-0.4-8,0.6-11.6,0.2
		c-2.2,4.4-4,7.7-6.2,12.1c-4.2-0.4-8.9,0.3-13.1-0.1c-0.4,2.5,0.1,4.4-0.4,6.9c-3.7,5.8-8.5,12.4-12.2,18.1
		c0.1,2.3,0.2,4.6,0.4,6.9C411.2,349.1,417.6,355.5,424,362z"/>
	<path class="st0" d="M265.3,33c6.3-0.1,12.5,0.6,18.8,0.4c6.6-10.4,13.2-20.7,19.8-31c10.7-1.1,21.2,0.7,31.4,5.4
		c0.1,2.2,0.3,4.4,0.4,6.6c13.2-3,14.9,13.5,18.8,25.4c10.6,0.4,20.8-0.2,31.4,0.2c-0.1,2.1-0.1,4.1-0.2,6.2
		c-23.3,8.9-36.8,21.6-45,38.8c-6-2.3-11.9-4.7-17.8-7c-4.4,4.2-8.9,8.6-13.2,12.8c-4.2-2.4-8.5-4.7-12.7-7.1
		c-2.1-6.5-4.1-13-6.2-19.4c-8.3-3.7-16.6-7.4-24.8-11.2C265.7,46.8,265.6,39.5,265.3,33z"/>
	<path class="st0" d="M385.7,172.3c4.2-6.5,8.3-13,12.4-19.4c0.1-14.6,0.3-29.2,0.4-43.8c-4.1,0-7.3-0.1-11.4-0.1
		c2.1-2.1,3.1-3.4,5.2-5.4c-8.4-5.9-7.6-14.5-0.4-24.2c0.1-7.3,0.3-14.6,0.4-21.9c-1.9-3.9-3.8-7.7-5.8-11.6
		c-19.1,7.1-35.3,18.8-46.3,39.2c4.8,8.4,9.6,16.8,14.5,25.3c-0.1,16.3-0.3,32.8-0.4,49.2C364.8,163.8,375.3,168,385.7,172.3z"/>
	<path class="st0" d="M265.6,33.4c0,12.7,0.1,25.3,0.1,38c-4.4,6.3-8.9,12.7-13.3,19c0,12.2,0,24.6,0,36.8c2.2,2.6,4.4,5.3,6.6,7.8
		c2.4-0.3,4.7-0.6,7.1-0.8c-0.3,6-0.6,12.1-0.9,18.1c4.2,0.4,8.6,0.9,12.8,1.2c0.3,4.3,0.6,8.6,0.9,12.8c12.7-2.3,24.6-0.2,37.3,6.2
		c2.3-4.4,4.5-8.1,6.8-12.5c3.6,2.4,8.1,4.3,11.6,6.8c6.6-6.6,13.2-13.2,19.9-19.8c0-12.6,0-25.1,0-37.7
		c-4.4-8.1-8.8-16.3-13.2-24.4c-6.2-2.6-12.4-5.2-18.6-7.8c-4.1,4.8-8.3,9.6-12.4,14.5c-4.5-2.4-9-5-13.6-7.4
		c-2-6.8-4.1-13.5-6.2-20.2c-8.1-3.5-16.2-6.9-24.3-10.4C265.8,46.9,265.7,40.2,265.6,33.4z"/>
	<path id="www.ivan-off.com_20_" class="st0" d="M315.9,173.1c2.7-4.5,4.4-8.2,7-12.7c3.7,2.7,8.1,4.2,11.8,6.9
		c6.5-6.8,13.2-13.3,19.7-20.2c0,4.2,0,8.5,0,12.7c10.6,4.3,21.2,8.6,31.8,12.8c2.1,2.4,4.1,4.7,6.2,7.1c-4.2,1.7-8.6,3.3-12.8,5
		c0.1,6.3,0.3,12.7,0.4,19c-10.8,12.9-22.2,24.9-37.6,31.4c-11.1-0.3-21.7-0.5-32.7-0.8c-0.1-8.7,0-17.6-0.1-26.3
		C315.8,196.6,317.4,185.3,315.9,173.1z"/>
	<path class="st0" d="M410.9,152.5c2.2,7,0.2,15.6-6.2,26.1c4.5,4.2,8.9,8.4,13.3,12.5c-9,8.4-18,17.4-27,25.8
		c10.5,8.9,13.5,13.5,13.4,18.2c3.7,3.2,8.3,4.7,12.3,7.1c0,4.4,0,8.8,0,13.2c7.1,4.3,14.1,7.6,19.4,4.6c0,2.7,0,5.5,0,8.2
		c-13-14.3-42.8-4.2-75.2,0.4c0.1-7,0.3-14,0.4-21.1c-4.8,0.1-9.6,0.3-14.5,0.4c-1.5-4.4-3-8.8-4.5-13.2c13.1-4.2,25.3-17.5,37.6-31
		c-0.1-6.2-0.3-12.4-0.4-18.6c4-2.1,8-4.2,12-6.3c-2.1-2.3-4.2-4.7-6.2-7c4.4-6.6,8.8-13.2,13.2-19.9
		C402.7,152.2,406.8,152.3,410.9,152.5z"/>
	<path id="www.ivan-off.com_19_" class="st0" d="M448.6,215.7c-1.9,15.3-4.8,30.3-12,44.6c-5.2,2.6-12-0.1-19.9-4.9
		c0.1-4.8-0.1-9.3,0.1-14.1c-4.3-2-8.6-4.1-12.8-6.2c0.7-7.6-5.8-12.9-13.2-17.8c9.2-9.1,17.8-17.1,27-26.2
		C425.7,203.2,436.9,210,448.6,215.7z"/>
	<path class="st0" d="M459.7,209.9c6.8,1.9,13.7,3.7,20.6,5.5c0,2.7,0,5.4,0.1,8.1c-16.6,10.2-24.5,23.3-13.7,42.6
		c0.3,6.5,0.6,13,0.9,19.3c-6.5,0.1-13.2-0.9-19.7-0.7c-6.6-4.4-8.4-9.5-11.4-16c-1-2.1,1.2-6.3,0.1-8.5c8.5-13.7,9-28.9,12-44.5
		C452.9,218.2,457.2,215.7,459.7,209.9z"/>
	<path class="st0" d="M480.4,234.3c6.5,0.1,13,0.3,19.4,0.4c10.2,8.3,20.4,16.6,30.5,24.8c1.5-1.7,3-3.3,4.5-5
		c4.8,4.4,9.6,8.8,14.5,13.2c-5.5,15.4-13.7,30.1-25.6,43.7c-6,2.1-12.1,4.1-18.1,6.2c-2.1-2.3-4.1-4.7-6.2-7
		c-8.9,8.6-17.6,18.3-26.5,27c-6.3-16.8-14-31.8-24.8-39.5c-0.1-4.5-0.3-9.1-0.4-13.6c6.5,0.3,13,0.6,19.4,0.8
		c-0.1-6.3-0.3-12.8-0.4-19.3c-9.4-15.5-5.4-30.1,13.2-42.8C480.1,227.2,480.3,230.4,480.4,234.3z"/>
	<path class="st0" d="M528.2,248.5c8.5-1.9,18.4-5.3,27-7.2c12.8,8.4,25.6,16.8,38.4,25.2c4.5,8.7,9.1,17.4,13.6,26
		c-15.3,8.3-3.1,17,12.4,30.2c-2.3,4.2-4.7,8.6-7,12.8c3.3,2.2,5.9,3.4,9.2,5.6c-9.9,12.4-17.4,21.7-27.4,34.1
		c-5.8-1.5-13.5-0.4-21.2-0.1c0,1.4,1.1,4.5,1.1,5.8c-4.2,3.6-10.2,5.4-18.4,5.3c-0.3-1.8-0.5-3.6-0.8-5.4
		c-4.3-2.4-8.6-4.9-12.8-7.4c0.1-8,0.3-16,0.4-24c3.5-13.6,10.9-16.8,19-18.6c-2.1-2.5-4.2-5-6.3-7.4c-6.6,0-13.2,0-19.8,0
		c-3.7-4.6-7.4-9.1-11.2-13.7c9-7.7,17.1-24.8,25.2-42.5C543,260.6,535,255.2,528.2,248.5z"/>
	<path class="st0" d="M529.1,387.5c-2.1,1.9-4.1,3.9-6.2,5.8c-22.6-11.5-45.2-23.4-56.6-43.1c2.2-5.8,4.6-7.1,6.8-13
		c8.6-8.5,18.1-18.1,26.6-26.6c1.9,2.2,3.8,4.4,5.8,6.6c6.5-2.4,13-4.9,19.4-7.4c3.7,4.5,7.4,9.1,11.2,13.7
		c6.8,0.1,13.8,0.2,20.7,0.4c1.9,2.2,3.8,4.5,5.8,6.6c-10.9,1.8-17.6,8.1-20.2,19c0,8,0,16,0,24c4,2.5,8,5,12,7.4
		c0.4,1.8,0.9,3.6,1.2,5.4c2.4,0.1,4.8,0.3,7.1,0.4c-0.6,2.9-1.1,5.8-1.7,8.6c-3.5,6.1-7.6,11.1-11,17.2
		C541.2,407,535.1,398.6,529.1,387.5z"/>
	<path id="www.ivan-off.com_18_" class="st0" d="M465.9,350c15,20.2,34.6,33.5,57.8,42.8c-12.4,3.9-24.8,8.8-37.2,12.6
		c0,4.2,0,8.6,0,12.8c-2.5,1.9-5,3.9-7.4,5.8c-6.1-4.1-12.2-8.2-18.2-12.4c4,4.2,8,8.3,12,12.4c-2.2,2.4-4.4,4.7-6.6,7.1
		c-3.9-0.1-7.8-0.3-11.6-0.4c2.1,2.1,4.1,4.2,6.2,6.2c-6.5,0.4-13,0.9-19.4,1.3c-16.4-3.7-28.5-12.8-37.6-25.6
		c4.2-11.3,9.2-21.2,13.5-32.5C433.8,370.3,449.5,359.5,465.9,350z"/>
	<path class="st0" d="M542.9,418.3c0.2-3.4,0.4-6.8,0.7-10.2c-5.5-5.5-9.7-13.2-13.5-21.5c-2.2,6.6-22.9,11.7-43.6,19.2
		c-0.1,4.4-0.2,8.8-0.4,13.1c-2.2,1.7-4.3,3.4-6.5,5.1c-6.3-4.1-12.6-8.3-18.9-12.4c4.2,4.4,8.5,8.7,12.7,13.1
		c-2.3,2.3-4.6,4.6-6.9,6.9c-4-0.4-8-0.7-12-1.1c4.4,4.3,8.7,8.7,13.1,13c-0.1,1.9-0.2,3.9-0.4,5.8c16.6,1.9,28.9,13.1,38.2,30.5
		c0.2-1.6,0.5-3.2,0.7-4.8c4.6,1.4,8.9,1.2,13.5,0.7c-2.3-2.5-4.6-5-6.9-7.6c4-4.2,8-8.3,12-12.4c-1.9-2.5-3.8-5.1-5.8-7.6
		c3.7-3.3,7.5-6.6,11.2-9.8c0.2-4.5,0.5-9,0.7-13.5C534.9,422.9,538.9,420.6,542.9,418.3z"/>
	<path class="st0" d="M398.6,430.3c1.8-6.1,3.6-12.2,5.5-18.2c9.5,12.8,21.3,22.8,38.2,26.6c0,3.2,0,6.5,0,9.8
		c-6.5,7-12.9,14-19.3,21.1c-2.3-0.1-4.6-0.3-6.9-0.4c-1.9-7-4.5-13.8-12-18.9C404.9,440.4,403.1,433.8,398.6,430.3z"/>
	<path id="www.ivan-off.com_17_" class="st0" d="M429.5,469.2c-1.9,0-3.9,0-5.8,0c6-6.6,11.9-13.1,17.9-19.7
		c0.1-4.4,0.2-8.7,0.4-13.1c6.1,0.1,12.3,0.3,18.5,0.4c2.5,2.1,5.1,4.2,7.6,6.2c-0.2,2.2-0.5,4.4-0.7,6.6
		c16.9,2.8,27.1,11.8,38.2,31.2c1.4,6.6-0.4,11.3-5.5,14.2c-8.2,0.9-16.3,1.9-25.4,0c0,1.8,0,3.6,0,5.5c-2.4,0.6-4.8,0.9-7.3-0.4
		c-1-1.5-1.2-3.5-0.4-5.8c-1.8-0.9-3.7-2.4-5.5-0.4c0.4,2.5-0.1,5-0.7,7.6c-4.2-1.7-8-1.9-11.2-0.7c0,2.3,0,4.6,0,6.9
		c-6.8-4.9-13.6-9.7-20.4-14.5C429.3,485.2,429.4,477.2,429.5,469.2z"/>
	<path class="st0" d="M403.7,518.6c4.6-10.9,11.3-20.5,25.1-25.1c6.9,4.1,13.8,8.8,20.7,13.8c-2.5,13.5,4.1,21.8,6.1,30.1
		c4.4,0,8.8,0,13.1,0c2.1,2.7,4.1,5.4,6.2,8.1c-4.1,1.9-8.3,3.8-12.4,5.8c6.7,5.5,3,6.2-2.2,6.2c-1.8-2.1-3.6-4.1-5.5-6.2
		c-2,0-4.1,0-6.2,0c0,1.9,0,3.9,0,5.8c-1.8-1.9-3.6-3.9-5.4-5.8c-1.7,4-3.9,7.5-6.6,10.6c1.9,0,3.9,0,5.8,0
		c-1.9,2.7-3.7,5.5-5.5,8.4c-8.9,0.6-17.7,1.2-26.5,0c-3.2-2.6-5.5-5.1-7.6-7.6c2.4-2.2,4.8-4.4,7.2-6.6c-0.7-3.7-0.2-7.5,0-11.3
		c-1.6-4.2-4-8.4-6.5-12.7c-2.1,0-4.2,0-6.2,0c-0.9-6.4-0.6-12.9,0.4-19.3C399.8,514.7,401.8,516.6,403.7,518.6z"/>
	<path id="www.ivan-off.com_16_" class="st0" d="M348.1,443c-0.1,1.9-0.2,3.9-0.4,5.8c-2.5,2.3-5,4.6-7.6,6.9
		c4.4,4.3,8.8,8.7,13.1,13.1c0.1,3.1,0.2,6.3,0.4,9.4c2.4,1.4,3.8,3.6,6.3,5c-0.1-2.4,0.8-4.5,0.6-6.9c4.7,16.1,11.1,13.7,18.9,18.6
		c1.9-2.2,3.7-4.4,5.5-6.6c2.6,1.9,5.1,3.9,7.6,5.8c4.2-1.9,8.5-3.9,12.7-5.8c3.5,4.1,7,8.3,10.5,12.4c4.5-2.7,9-5.3,13.5-8
		c0-7.9,0-15.8,0-23.6c-3.9,0.2-8.8,0.1-12.7,0.4c-2.4-8.4-6-14.5-12.2-20.2c-1.4-1.3-0.1-4.7-0.9-6.4c-1.9,0.1-3.9,0.3-5.8,0.4
		c-2.1-2.2-4.1-4.3-6.2-6.6c-4.7,9.3-13.7,7.2-24.7,0c0.2,2.2,0.5,4.4,0.7,6.6C361,443.3,354.5,443.1,348.1,443z"/>
	<path class="st0" d="M417.1,379c2.7-4.5-1.1-10.7-5.8-17.1c-8.6,0-17.2,0-25.8,0c-7.5,1.9-15,5.7-19.3,13
		c-6.3,3.2-15,3.6-17.8,12.1c-4.5-0.2-8.9-0.5-13.4-0.7c-1.7,21.4,5.2,39.3,12.7,57c6.5,0.1,12.9,0.3,19.3,0.4
		c-0.2-2.4-0.5-4.8-0.7-7.3c14,9.4,21.6,8.4,24.7,0.4c4.8,6,9.1,8.3,13.1,6.9c0.6-5.5-1.4-10.1-6.2-13.8
		C404.3,413,410.7,396,417.1,379z"/>
	<path id="www.ivan-off.com_15_" class="st0" d="M323.7,450.3c0,7.8,0,15.5,0,23.3c-9.1,0.1-18.1,0.3-27.1,0.4
		c-1.3-1.7,1.4-4.8-0.3-6c-5-3.7-11.4-8.8-11-18c-3.5,3.2-8.1,6-13.5,8.4c1.2-3.2,0.4-6-0.4-8.4c-1.4,6.4-5.5,8.7-12.3,6.9
		c-0.1-7.5-2.1-11.4-5.8-11.7c-0.1-7.1-0.2-14.3-0.4-21.4c-1.6,0-3.2,0-4.8,0c33-15.7,58.6-23.1,67.9-12.4
		c6.3-1.8,12.6-3.6,18.9-5.5c0.1-6.5,0.2-13,0.4-19.6c-2.6,11.2,2.4,31.8,12.1,56.2c0.9,2.4-1,4.4-0.1,7
		C339.2,449.8,332,450,323.7,450.3z"/>
	<path class="st0" d="M234.1,393.6c16.3-10.4,30.2-22.6,39.2-38.5c1.7,4.2,3.4,8.1,5,12.4c16-2.5,26.3-10.7,31.2-25.1
		c8.6,7.1,14.7,15.4,19.7,24.3c-1.5,8.4-1.9,16.2,5.8,20c0.2,6.3,0.4,12.6,0.7,18.9c-7.1,2.2-12.7,4-19.7,6.2
		c-8.1-12-37.1-1.7-68.9,12.7c-7-4.4-7.6-12-7.3-20c2.1,0.4,4.2,0.7,6.2,1.1C242.1,401.6,238.1,397.6,234.1,393.6z"/>
	<path class="st0" d="M227.5,387c1.9,2.5,3.9,5.1,5.8,7.6c15.6-10.2,30-22.1,40-39.6c1.8,4.5,3.6,8.3,5.4,12.7c16-3,26-11.9,32-25.8
		c1-7.6,5.8-13.7,13-18.9c1.7-6.1,3.4-12.2,5.1-18.2c-6.2-2.3-12.4-4.6-18.6-6.9c-12.7,4.5-25.6,9-38.4,13.5
		c-27,14.5-21.1,49-44.4,64.3l5.5,4.8L227.5,387z"/>
	<path class="st0" d="M335.8,304.9c3.9-8.5,7.7-16.9,11.6-25.3c2.3,0,4.6,0,6.9,0c1.9-8.5,4.3-16.8,6.3-25.3
		c0.1,5.1-0.2,10-0.1,15.1c30.6-3.5,63.3-16.9,76-1.2c3.7,6,5.1,13.2,11.2,17.8c-0.1,4.6,0.4,7.8,0.4,12.5c-3.7-0.4-8,0.6-11.6,0.2
		c-2.2,4.4-4,7.7-6.2,12.1c-4.2-0.4-8.9,0.3-13.1-0.1c-0.4,2.5,0.1,4.4-0.4,6.9c-3.7,5.8-8.5,12.4-12.2,18.1
		c0.1,2.3,0.2,4.6,0.4,6.9c6.4,6.4,12.8,12.8,19.2,19.3c-2.3,3.2-4.6,6.6-6.9,9.8c-2.2-3.5-4.3-7.1-6.5-10.5
		c-8.5,0.2-17,0.4-25.5,0.7c-1.6-13.6-5.7-21-10.9-25.5c-4.6-0.1-9.2-0.2-13.8-0.4c-0.1-4.3-0.2-8.7-0.4-13
		C352,317,343.9,310.9,335.8,304.9z"/>
	<path class="st0" d="M247.8,291.4c3.7-12,7.3-24.1,10.9-36.2c8.2-0.6,17,2.3,25.8,5.8c8.8,21.6,29.7,26.7,43.5,44.2
		c-6.2-2.4-12-4.5-18.1-6.9c-12.5,4.5-25.1,9-37.7,13.5C264.1,305,256,298.3,247.8,291.4z"/>
	<path id="www.ivan-off.com_14_" class="st0" d="M209.2,235.3c7.4-0.2,12.4,0.2,19.8-0.1c7.6,1.2,14.6,7,18.6,19.2
		c3.7,0.4,8.4-0.1,12.2,0.2c-3.7,12.3-7.9,26-11.6,38.3c-10.5-3.1-19.2-0.6-26.5,5.8c-12.5-0.2-25-0.3-37.4,5.8
		c2.1-19,4.1-37.9,6.2-57c2.3,0.1,4.6,0.2,6.9,0.4C200.9,244.2,205.7,239.1,209.2,235.3z"/>
	<path class="st0" d="M215.5,173.1c7.3-18.1,23.3-31.5,37.4-45.8c2.1,2.7,4.1,5.3,6.2,8c2.2-0.1,4.3-0.2,6.6-0.4
		c0,6.2,0,12.4,0,18.6c4.2,0.2,8.4,0.4,12.7,0.7c0.1,5.5,0.2,12,0.8,18.6c3.5,8.8,5.8,17.4-0.5,23.3c-0.4,6.8,0.4,13.7,0.4,20.5
		c-4.7,1.8-9.4,3.6-14.1,5.4c-4-10.1-7.5-18.3-11.4-24c-7.6-3-15.2-5.5-24.6-6.1C224.5,185.7,220,179.4,215.5,173.1z"/>
	<path class="st0" d="M177.4,191.2c13-6,25.9-12.1,38.9-18.1c0.7-8.6,16.4-25.8,36.4-45.4c0-12.2,0-24.5,0-36.7
		c4.6-6.5,9.2-13,13.8-19.6c-0.7-15-0.6-23.3-1.3-38.3c-2.8,3.9-4.3,3-6,6.3c-5.7-1.9-11.7-4-18.6-6.6c-2.7,2.9-4.7,5.6-7.3,8.5
		c2.6,5.7,4.5,11.6,7,17.3c-2.2,0-4.3,0-6.5,0c0.1,10.9-0.2,19.7-0.1,30.5c-4.2-4.5-7.8-6.8-12-11.2c-6.8-0.1-13.5-0.3-20.4-0.4
		c-9.3,8.6-18.2,15.9-24.3,12.7c-4.3,4.6-7.6,7.8-11.9,12.4c-3.4-2.8-8-4.1-11.4-6.9c-12.7,10.4-20.9,24-33.4,20
		c9.2,24.5,26.6,34.1,39.3,45.4c2,5.6,4.4,13,6.5,18.5C169.9,183.5,173.6,187.4,177.4,191.2z"/>
	<path class="st0" d="M46.2,146.9c-11.6-2.9-17.6-1.2-19.8,6.3c0.4,4.4-0.1,9.4,0.2,13.7c-7.2-2.4-9.3-0.1-6.2,6.9
		c8.4,6.2,8.2,12-0.7,17.4c-6-5-11.9,6.4-17.9,26.6c8.3,10.2,14.3,19.2,17.1,30.2c8.9-0.1,17.9-0.3,26.9-0.4
		c16.7-6.3,31.6-16.4,44.4-31.2c0-17.1,0-34.2,0-51.3c-11.6-1.3-24.4-0.5-30.9-10.2C52.9,154.2,48.5,151.5,46.2,146.9z"/>
	<path class="st0" d="M84.3,160c6-2.3,11.9-4.6,17.8-6.9c4.5,2.4,9,4.9,13.5,7.3c6.3-6.5,12.6-13,18.9-19.6
		c12.4,9.4,20.7,14.5,25.3,20.7c3.5,4.8,4.2,10.9,5.9,16.4c-9.4,4-16.9,8.4-12,14.9c1.2,1.8,2.4,3.6,3.7,5.4
		c-7.6,12.4-15,24.8-22.5,37.1c-2.6-1.9-5.1-3.9-7.6-5.8c-9.1,5.2-17.6,11.1-24.3,18.6c1.8,4.1,3.6,8.2,5.4,12.3
		c-18.5,0.1-32.5-7.8-38.4-25.2c6.6-5.4,13.7-11,20.2-18c-0.1-17-0.3-33.9-0.4-50.9C88,164.1,86.1,162,84.3,160z"/>
	<path id="www.ivan-off.com_13_" class="st0" d="M45.1,123.3c-2.2-0.3-4.4-0.5-6.6-0.7c-0.1-6.6-0.2-13.1-0.4-19.7
		c2.5-2.3,5.1-4.6,7.6-6.9c2.9,3.3,4.9,7.5,6.2,12.4c-0.2-2.3,1.3-4.9,1-7.2c2.8-4.6,5.8-6.1,12.5-5.2c-0.3,2.2-0.5,4.4-0.7,6.6
		c2-0.1,4.1-0.2,6.1-0.4c-1.5,11,1.8,16.2,5.8,19.6c0-2.3,0.6-4.6,0.6-6.8c4.5,0,9,0,13.5,0c-0.5,6.8,1.7,10.3,6,12.3
		c-0.1-1.9-0.3-3.9-0.4-5.8c4.3,0,8.7,0,13,0c-0.1,1.9,0.1,4.2-0.1,6c1.7,0,3.5,0,5.1,0c-1.3-4.5,0.2-9.2,5.2-13
		c5,8.8,10.2,17.5,15.3,26.2c-6.6,6.8-13.1,13.5-19.7,20.4c-4.1-3.1-8.2-6.1-12.3-9.1c-6.6,2.7-13.1,5.3-19.7,8
		c2.2,1.9,4.4,3.9,6.6,5.8c-13.3-1.5-24.9-2.5-30.3-11.1c-5.7,0.2-10.7-4-14.8-8.5C44.9,138.6,44.9,130.9,45.1,123.3z"/>
	<path class="st0" d="M19.3,248.7c0.4,6.6,0.9,13.1,1.4,19.6c4,3.7,8,7.3,12,10.9c-2.2,4.1-4.3,8.1-6.5,12.2c-2.1,0-4.2,0-6.2,0
		c-6.6,14.3-8.4,25.8,0,31.3c0.2,6.6-0.7,13-0.5,19.7c-2.4,4.2-3.6,8.8-6,13c8.8,1.9,14.8,6.1,18.1,12.7c4.8-2,9.4-4.1,14.2-6.1
		c2.1,2.5,4.1,5,6.2,7.6c2.8-3,5.5-6,8.4-9.1c-2.5-2-5.1-2.9-7.6-5c5.9-1.2,11.9-3.6,17.8-4.8c4.4,5.8,7.6,11.4,12,17.1
		c4.1-2,9.4-3.8,13.5-5.8c4.2,2.2,8.4,4.3,12.7,6.6c-4.1-8.8-8.2-17.5-12.3-26.2c8.6-1.9,17.2-3.3,25.8-11.6
		c-0.2-2.6-0.5-5.1-0.7-7.6c4.5,0.1,7.9,0,12.4,0.2c-0.3-14.9,0.6-29.6,0.3-44.4c-8.5-6-17-12.1-25.5-18.2
		c-19.5,1.1-31.6-8.6-38.5-25.8c-7.5,5-15.3,9.4-23.6,12C37.5,247.4,28.4,248.1,19.3,248.7z"/>
	<path class="st0" d="M165.8,197.4c6.4,8.5,12.8,17,19.2,25.5c-2.5,3.8-5,7.7-7.6,11.6c0,4.6,0,9.2,0,13.8c4.4,0.1,8.7,0.2,13.1,0.4
		c-1.9,18.9-3.9,37.7-5.8,56.6c-8.2,4.7-10.9,11.5-12.7,18.6c-12.7,0.2-25.4,0.4-38.1,0.7c0.2-15.3,0.4-30.5,0.7-45.7
		c-8.9-6.3-17.7-12.6-26.6-18.9c-2-4.1-4.1-8.2-6.2-12.3c8.5-6,17-12.1,25.5-18.2c2.3,1.9,4.6,3.7,6.9,5.5
		c7.7-12.5,15.5-24.9,23.3-37.4C160.1,197.4,163,197.4,165.8,197.4z"/>
	<path class="st0" d="M260.2,229.6c-0.1-0.1-0.3-0.2-0.4-0.2c6.1-4.1,12.3-8.2,18.4-12.3l-0.1,0c-4.4,1.7-8.8,3.4-13.1,5
		c-4-10.1-7.5-18.3-11.4-24c-7.6-3-15.2-5.5-24.6-6.1c-4.4-6.3-8.9-12.4-13.3-18.7c-12.8,6-25.6,11.9-38.3,17.9
		c-3.7-3.8-7.5-7.7-11.2-11.6c-0.2-0.5-0.4-1.1-0.6-1.7c-9.2,4-16.6,8.4-11.8,14.8c1.1,1.7,2.3,3.4,3.4,5.1c0.1-0.1,0.2-0.3,0.2-0.4
		c2.7,0,5.5,0,8.4,0c6.4,8.5,12.8,17,19.2,25.5c-2.5,3.8-5,7.7-7.6,11.6c0,4.6,0,9.2,0,13.8c4.4,0.1,8.6,0.2,13,0.4
		c0-0.4,0.1-0.7,0.1-1.1c2.3,0.1,4.6,0.2,6.9,0.4c3.5-3.7,8.4-8.9,11.8-12.6c7.4-0.2,12.4,0.2,19.8-0.1c7.5,1.1,14.5,6.9,18.5,18.8
		C251,245.9,254.5,237.7,260.2,229.6z"/>
	<path class="st0" d="M305.8,256.4c-2,2.6-4.2,4.8-6.7,6.5c-2.7,0.3-5.5,0.6-8.3,0.8c-1.2-2.4-1.9-5-0.4-8.1c0-0.1-0.1-0.2-0.1-0.3
		c-2.1,1.8-4,3.5-6.1,5.3c8,20,27.8,26.9,44.3,45c2.7-0.4,5.3-0.7,8-1.1c3.7-8.1,7.5-16.2,11.2-24.3
		C333.8,272.3,319.8,264.3,305.8,256.4z"/>
	<path class="st0" d="M246.8,291.9c-10.4-0.6-19.2,1.6-24.8,7.3c-6.8-0.2-12.7-0.1-19.5-0.4c-17.3,3.1-26.6,11.6-30.5,25.5
		c-3.9,0-7.9-0.1-11.8-0.2c-8.8,0.2-17.5,0.3-26.3,0.5c0-0.4,0-0.8,0-1.3c-4.1-0.1-8.3-0.2-12.4-0.2c0.1,2,0.6,5.2,0.7,7.3
		c-6.3,7.1-15.6,9.9-25.8,11.6c4,8.5,8,17,12,25.4c5.1,6.3,12.5,5.9,19.2,7.3c3.2-7.1,7.3-8.1,13.5-5.8c6.1-6.1,12.2-12.2,18.2-18.2
		c4.6-0.2,9.2-0.5,13.8-0.7c-0.1-2.2-0.3-4.3-0.4-6.6c3.7,0,7.5,0,11.2,0c-2.4,6.4-4.8,12.9-7.3,19.3c6.9,0,13.8,0,20.7,0
		c3.9,3.6,7.7,7.3,11.6,10.9c0.3-4,0.5-8,0.7-12c2.1,0.1,4.2,0.2,6.2,0.4c3.9,4.6,7.8,9.2,11.7,13.8c26.6-19.7,17.3-50.3,45.1-64.7
		C264,304.7,255.4,298.3,246.8,291.9z"/>
	<path class="st0" d="M346.5,247.5c-1.1-4.2-2.2-8.5-3.3-12.8c-11.2,0.1-22.3,0.3-33.5,0.4c0,4.2,0,8.6,0,12.8c-1.1,0-2.1,0-3.2,0
		c0.5,2.4,0.4,5,0,7.6c-0.1,0.2-0.2,0.3-0.4,0.5c14,8.1,28,16.1,42,24.1c2.1-0.3,4.1-0.6,6.2-0.9c2.4-10.7,5-21.5,7.4-32.2
		C356.7,247.3,351.6,247.4,346.5,247.5z"/>
	<path class="st0" d="M310,208.2c2.4-12.7,8.9-17.6,5.4-35.9c-12.1-6.2-24.1-8-36.3-5.4c-0.1,2.2-0.1,4.3-0.1,6.5
		c5.3,8.1,4.5,15.8-0.6,23.3c0.1,4.7,0.3,9.4,0.4,14c8.4,11.1,17.2,22.1,23.9,33.8c0.9,0,1.9,0.1,2.8,0.2c0.4,1.1,0.8,2.3,1,3.5
		c1.1,0.1,2,0.2,3.6,0.2C311.2,235.3,307.6,220.9,310,208.2z"/>
	<path class="st0" d="M290.8,246.7c4.1-1.5,8.2-2.3,12.3-2.2c-7.8-11.5-15.5-22.5-23.5-34.2c-0.4,2.2-0.8,4.4-1.2,6.6
		c-6.2,4.1-12.4,8.2-18.6,12.4c10.2,6,20.5,12,30.8,18C290.6,247.1,290.7,246.9,290.8,246.7z"/>
	<path class="st0" d="M290.3,248c-10.4-6.3-19.2-12.6-29.7-19c-5.9,8.4-9.5,16.8-13.1,25.1c10.9-0.4,23,1.2,37.4,6.1
		c2.1-1.6,3.4-3.2,5.4-4.8C289.4,252.9,289.4,250.5,290.3,248z"/>
	<path id="www.ivan-off.com_11_" class="st0" d="M290.4,255.6c-1.2-3-1-5.9,0.4-8.9c5-1.8,9.9-2.7,14.8-2c1.3,3.3,1.6,7,0.9,10.9
		c-2.2,3-4.5,5.5-7.3,7.3c-2.7,0.3-5.5,0.6-8.3,0.8C289.7,261.2,288.9,258.7,290.4,255.6z"/>
</g>
</svg>

</template>

<style lang="scss">
#map-linear {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;

  fill: none;
  stroke-width: 2;
  stroke: rgb(99, 102, 241);
  opacity: 0.75;
}
</style>
